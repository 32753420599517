import { useSpring, animated } from '@react-spring/web'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useReportError } from '/machinery/ReportError'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'
import { DocumentCardLightBlue, ImageCard, VideoCard, QuoteCard, EventCard } from '/features/pageOnly/LifeAtKLM/ContentCards'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ContentSlider.css'

export function ContentSlider({ items }) {
  const isMounted = useRenderOnMount()
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm) ?? false
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const initialSlide = 1
  const loop = true
  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: items.length,
    slidesPerView: isViewportSm ? 'auto' : 1,
    spacing: isViewportMd ? 32 : 16,
    initialSlide,
    sliderDisabled: false,
    origin: 'center',
    loop,
    interactionTitle: 'content-slider'
  })

  return (
    <section data-x='content-slider' className={styles.component}>
      <div className={styles.container}>
        <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
          {items.map((item, i) => (
            <div key={i} className={cx(styles.slide, 'keen-slider__slide')}>
              <Slide layoutClassName={styles.slideLayout} {...{ item }} />
            </div>
          ))}
        </div>
      </div>
      {isMounted && (
        <ContainerXl>
          <SliderButtons maxSlides={items.length} {...{ currentSlide, loop, handleClick }} />
        </ContainerXl>
      )}
    </section>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}

function Slide({ item, layoutClassName = undefined }) {
  const slideRef = React.useRef(null)
  const [offsetX, setOffsetX] = React.useState(0)

  React.useEffect(
    () => {
      const { x } = slideRef.current.getBoundingClientRect()
      setOffsetX(x)
    },
    []
  )

  const { y } = useSpring({
    y: Math.sin(offsetX * 1.2) * 100,
    config: { mass: 0.5, tension: 600, friction: 50, precision: 0.01, velocity: 0 },
  })

  return (
    <animated.div
      ref={slideRef}
      style={{ y }}
      className={cx(styles.componentSlide, layoutClassName)}
    >
      <Card {...{ item }} />
    </animated.div>
  )
}

function Card({ item }) {
  const reportError = useReportError()

  switch (item._type) {
    case 'article': return <DocumentCardLightBlue layoutClassName={styles.documentCardLayout} {...{ item }} />
    case 'project': return <DocumentCardLightBlue layoutClassName={styles.documentCardLayout} {...{ item }} />
    case 'imageCard': return <ImageCard layoutClassName={styles.imageCardLayout} {...{ item }} />
    case 'videoCard': return <VideoCard layoutClassName={styles.videoCardLayout} {...{ item }} />
    case 'quoteCard': return <QuoteCard layoutClassName={styles.quoteCardLayout} {...{ item }} />
    case 'event': return <EventCard layoutClassName={styles.eventCardLayout} {...{ item }} />
    default: {
      reportError(new Error(`Unknown card type '${item._type}'`))
      return null
    }
  }
}
